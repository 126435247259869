// In your _sale-page.scss file
.layout-container {
  display: flex;
  justify-content: space-between;

  .signature-section {
    flex: 1;
    margin: 0 10px;

    .signature-container {
      position: relative;
      width: 635px; // Set a fixed width to match the canvas
      height: 200px; // Set a fixed height to match the canvas
    }
    

    .signature-pad {
      border: 2px solid #9ef316; // Beautiful rectangle around the signature pad
      padding: 0px;
      width: 100%;
      height: 100%;
    }

    .signature-pad-agent {
      border: 2px solid #f31616; // Beautiful rectangle around the signature pad
      padding: 0px;
      width: 100%;
      height: 100%;
    }

    .agent-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.15);
      pointer-events: none;
    
      span {
        position: absolute;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.15);
        transform: rotate(-30deg);
        // You can adjust these values to position the text exactly where you want it
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
      }
    }

    .pdf-container {
      border: 1px solid #9ef316; // Use a bright color for visibility
      padding: 5px; // Add some padding to separate the canvas from the border
    }

    .button-container {
      display: flex; /* This will place all child elements in a row */
      align-items: center; /* This aligns the buttons vertically in the middle, if needed */
      justify-content: start; /* This aligns the buttons to the start of the container, optional */
    }

    .custom-icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3rem; /* or any other specific size */
      padding: 0 1rem; /* horizontal padding */
    }
  }

  .document-list-section {
    width: 300px; // Adjust as needed

    .document-item {
      cursor: pointer;
      padding: 2px;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f3f3f3;
      }

      &.current-document {
        background-color: #e0e0e0; // Highlight color for the current document
      }

      .signed-icon {
        color: green;
      }

      .unsigned-icon {
        color: red;
      }
    }
  }

  .sticky-sidebar {
    width: 300px; // Adjust as needed
    position: sticky;
    top: 20px;
    padding: 20px;
    border-left: 1px solid #ddd;

    .signature-pad {
      border: 2px solid #9ef316; // Beautiful rectangle around the signature pad
      padding: 0px;
    }
  }
}
