.analitika-container {
    padding: 20px;
}

.total-sales {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.filter-section {
    margin-bottom: 40px;
    width: 100%;
}

.filter-buttons {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.agent-section {
    margin-bottom: 60px;
    width: 100%;
}

.full-width-progress {
    margin-bottom: 20px;
}


.progress-bar-item {
    margin-bottom: 15px;
}

.dashboard-chart {
    background-color: #161616;
    padding: 20px;
    border-radius: 8px;
    color: #FFFFFF;
}

.text-white {
    color: #FFFFFF;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.tile-custom {
    background-color: #161616;
    padding: 20px;
    border-radius: 8px;
    color: #FFFFFF;
    text-align: center;
}

.table-header {
    background-color: #161616;
}

.table-cell {
    background-color: #161616;
    color: #FFFFFF;
}

.full-width {
    width: 100%;
}

.section-width-full {
    width: 100%;
}

@media (max-width: 768px) {
    .filter-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}