.terrains-summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.full-width-progress {
    width: 100%;
}

.progress-bars-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.progress-bar-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}