/* _authentication_page.scss */

.auth-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: black; // Make background black
    color: white; // Make font color white

    img {
        max-width: 100%; /* Ensures the image does not exceed its container's width */
        height: auto; /* Maintains the aspect ratio */
        object-fit: contain; /* Ensures the image is scaled properly */
    }
}

.auth-container input {
    margin-bottom: 15px;
    background-color: #333; // Darker input background for visibility
    color: white; // Input text color
    border: none; // Optional: remove border or style it as needed
}

.auth-container .custom-button {
    width: 100%;
    background-color: #BDF761; // Button background color changed to lime green
    color: black; // Adjusts button text color for contrast if needed
    border: none; // Removes border or style it as per the design
    // Consider adding :hover and :focus styles for better user interaction
    &:hover {
        background-color: darken(#BDF761, 10%); // Optional: Darken on hover for visual feedback
    }
    &:focus {
        outline: 2px solid darken(#BDF761, 20%); // Optional: Outline on focus for accessibility
    }
}

.notification {
    margin-top: 20px;
}

.centered-text {
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    padding-left: 0;  // Resetting any default padding
    padding-right: 0;
}

  