
.dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dashboard-header h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .dashboard-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .dashboard-section {
    margin-bottom: 20px;
  }
  
  .dashboard-metrics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .metric {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .metric p {
    margin: 5px 0;
  }
  
  .dashboard-charts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .dashboard-chart {
    flex: 1;
    min-width: 400px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }
  
  .visits-list {
    list-style: none;
    padding: 0;
  }
  
  .visit-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .visit-item:last-child {
    border-bottom: none;
  }
  
  .visit-item p {
    margin: 5px 0;
  }
