.plan-import-app {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  
    .section {
      margin-bottom: 20px;
  
      label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
      }
  
      .create-plan {
        margin-top: 10px;
  
        .bx--text-input { /* Specifična klasa za Carbon TextInput */
          margin-bottom: 10px;
        }
  
        Button {
          margin-top: 10px;
        }
      }
  
      .import-progress-bar {
        margin-top: 10px;
      }
    }
  }