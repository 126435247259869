// Define custom colors
$green10: #dffbb1;
$green20: #bdf761;
$green30: #aef53d;
$green40: #9ef316;
$green50: #88d50b;
$green60: #6fae09;
$green70: #568807;
$green80: #3e6105;
$green90: #253a03;
$green100: #0c1301;
$green40hover: #9ee600;
$ared40: #f31616;
$ared40hover: #d92929;
$ared60: #c00000;
$ared80: #730000;
$orange60: #b87009;
$agray40: #a8a8a8;
$agray50: #8d8d8d;
$agray60: #6f6f6f;


@use '@carbon/react/scss/themes' as *;

// DOCS:
// https://carbondesignsystem.com/migrating/faq/
// tokens: https://carbondesignsystem.com/elements/color/tokens

//overriding theme tokens and adding a custom token
@use '@carbon/react/scss/theme' with (
  $theme: (
    background-brand: $green40,
    border-interactive: $green40,
    icon-interactive: $green40,
    button-primary: $green40,
    button-primary-hover: $green40hover,
    button-primary-active: $green60,
    button-secondary: $agray40,
    button-secondary-hover: $agray50,
    button-secondary-active: $agray60,
    button-tertiary: $green40,
    button-tertiary-hover: $green40hover,
    button-tertiary-active: $green60,
    button-danger-primary: $ared40,
    button-danger-hover: $ared40hover,
    button-danger-active: $ared60,
    focus: $green40,
    interactive: $green40,
    highlight: $green20,
    text-on-color: #000000,
    link-primary: $green60,
    link-primary-hover: $green70,
    link-secondary: $green70,
    link-inverse: $green40,
    link-visted: $orange60,
    //custom-token: #88d50b,  // example of a completely new token
    text-error: $ared60,
  )
);

// override a component token
@use '@carbon/react/scss/components/button/tokens' as button-tokens with (
  $button-primary: $green40,
  $button-primary-hover: $green40hover,
  $button-primary-active: $green60,
  $button-secondary: $agray40,
  $button-secondary-hover: $agray50,
  $button-secondary-active: $agray60,
  $button-tertiary: $green40,
  $button-tertiary-hover: $green40hover,
  $button-tertiary-active: $green60,
  $button-danger-primary: $ared40,
  $button-danger-hover: $ared40hover,
  $button-danger-active: $ared60

);
@use '@carbon/react';

//@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  @include theme.theme();
}

body {
  font-family: 'Roboto', sans-serif;
}

/*
// You can access a custom variable via the theme.get() mixin
.custom-token {
  background: rgba(theme.get('custom-token'), 0.95);
}
*/