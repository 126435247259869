.delete-button {
    color: red;
}

.delete-button:hover {
    color: red;
}

.admin-page {
    background-color: #121212;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-content {
    padding: 16px;
    color: #ffffff;
}

.header {
    z-index: 100;
}