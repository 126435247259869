
  // In your _sale-page.scss file
.document-section {
  margin-top: 20px;
  padding: 5px;
  border: 1px solid #ddd; // Light border for the section
  background-color: #f9f9f9; // Light background
  border-radius: 5px; // Optional, for rounded corners
  width: 140%;

  .section-title {
    font-size: 1.1em; // Example size, adjust as needed
    font-weight: bold;
    margin-bottom: 15px; // Adds some space below the title
  }

  .document-list {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .document-icon {
        margin-right: 10px; // Space between icon and text
      }
    }
  }
}

.activeButton {
  background-color: #0f62fe; /* Active color */
  color: white;
}

.defaultButton {
  background-color: #e0e0e0; /* Default color */
  color: black;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Allows items to wrap */
}

.form-half-width {
  flex: 1; /* Each item will take up equal space */
}

.form-quarter-width {
  flex: 0.5; /* Each item will take up half the space of a .form-half-width */
}

.form-third-width {
  flex: 0.33; /* Each item will take up half the space of a .form-half-width */
}

.form-two-thirds-width {
  flex: 0.67; /* Each item will take up half the space of a .form-half-width */
}

.additional-packages {
  margin-top: 20px;
}

.additional-package {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-wrapper {
  display: flex;
  flex: 1; /* Takes all available space pushing buttons to the end */
}

.dropdown-container {
  flex: 1; /* Each container takes equal space */
}

.bx--dropdown {
  flex-grow: 1; /* Each dropdown takes equal amount of space within the container */
  margin-right: 10px; /* Space between dropdowns */
}

.button-container {
  display: flex;
  flex-grow: 0; /* Prevents button container from growing */
}

.button-container-end {
  display: flex;
  justify-content: flex-end; // Align items to the right
  margin-top: 10px; // Optional: add some space on top of the button container
}

/*
.sale-page-container {
  display: grid;
  grid-template-columns: 1fr; // This sets up a single column grid layout
  gap: 20px; // Adds space between the rows
}

.tab-container {
  // Styles for your tab container, if necessary
}

.progress-indicator-container {
  width: 100%; // Ensure the progress indicator spans the full width of the container
  // Any additional styles for the progress indicator container
}

*/

// 1“in the previous example, the height of the page is fixed
/*.sale-page-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  
    .form-section {
      flex: 1;
      margin: 0 10px;
    }
  }
  */

  .gdpr-form {
    .gdpr-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        vertical-align: top;
      }
  
      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
  
      .bx--checkbox-wrapper {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  
    .form-actions {
      display: flex;
      gap: 16px;
    }
  }
  
  .floating-microphone-button {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .floating-microphone-button:hover {
    transform: scale(1.1);
  }
  
  .floating-microphone-button svg {
    width: 32px;
    height: 32px;
    fill: white;
  }
  
  .floating-microphone-button .cds--btn__icon {
    position: static;
    margin: 0;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(218, 30, 40, 0.7);
    }
    70% {
      transform: scale(1.05);
      box-shadow: 0 0 0 10px rgba(218, 30, 40, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(218, 30, 40, 0);
    }
  }
  
  .floating-microphone-button.recording {
    animation: pulse 2s infinite;
  }

  .floating-mic-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

.floating-microphone-button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.floating-microphone-button:hover {
  transform: scale(1.1);
}

.transcript-container {
  margin-top: 1rem;
  max-width: 300px;
  word-wrap: break-word;
}

.transcript-container .cds--tag {
  max-width: 100%;
  white-space: normal;
  height: auto;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.4;
}