.map-sticky {
    position: sticky;
    top: 0;
    height: 100vh; /* 100% of the viewport height */
    z-index: 1;
  }
  
/* Add specific styles for table cells that contain the icons */
.table-cell-icons {
  min-width: 150px; /* Adjust this value based on your content */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; /* Aligns content to the start of the cell */
}

  .prikljucki-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.prikljucki-icon {
  padding: 0;
  position: relative; // Set the position context for the absolute child
  margin-right: 3px; // Space between icons
  display: flex;
  align-items: center;
  justify-content: center; // Center the icon horizontally
  border: 1px solid #ddd; // Border around the entire component
  border-radius: 20%; // Circular border for the icon
  width: 30px; // Width of the icon container
  height: 30px; // Height of the icon container

  &:hover {
    transform: scale(1.05); // Slightly enlarge the component on hover
    cursor: pointer;
    border-color: #aaa; // Change border color on hover for effect
    
    //.prikljucki-number {
    //  opacity: 0; // Hide the number on hover
    //  transition: opacity 0.3s ease; // Smooth transition for the opacity
    //}
  }
}

.prikljucki-icon img {
  width: 100%; // Icon fills the container
  height: 100%; // Maintain aspect ratio
}

// takes care of the tooltip visibility
.cds--data-table-content {
  overflow-x: visible;
}


.prikljucki-number {
  position: absolute; // Absolutely position the number
  bottom: 2px; // Position from the bottom of the container
  right: 2px; // Position from the right of the container
  font-size: 0.75rem; // Smaller font size to fit the corner
  color: #fff; // White color for contrast
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background for legibility
  padding: 0 3px; // Padding around the number
  border-radius: 3px; // Rounded corners for the number background
  font-weight: bold; // Bold for better visibility
  line-height: 1; // Tight line height for the number
  min-width: 14px; // Minimum width to handle single digits
  text-align: center; // Center the text within the number background
}

.obisk-modal-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  //gap: var(--cds-spacing-05);
  align-items: center;
}

.obisk-modal-button {
  //padding: var(--cds-spacing-03) var(--cds-spacing-05);
  //font-size: var(--cds-type-size-05);
  //text-align: center;
  height: 3rem;
  font-size: 1.5rem;
  width: 100%; /* Makes the button expand the full width of the modal */
}

.modal-size .cds--btn--primary,
.modal-size .cds--btn--secondary {
  //padding: var(--cds-spacing-03) var(--cds-spacing-05);
  //font-size: var(--cds-type-size-05);
  //text-align: center;
  //height: 3rem;
  font-size: 1.4rem;
  width: 100%; /* Makes the button expand the full width of the modal */
}




@media (min-width: 992px) {
  .obisk-modal-content {
    gap: 0.1rem;
    //gap: var(--cds-spacing-04);
  }

  .obisk-modal-button {
    height: 3.5rem;
    font-size: 2rem;
    //padding: var(--cds-spacing-04) var(--cds-spacing-06);
    //font-size: var(--cds-type-size-04);
  }
}

/* You may need to adjust the size of the modal based on your content 
.modal-size {
  //width: 80vw; /* Example: 80% of the viewport width * /
  max-width: var(--cds-modal-sm); /* Adjust based on Carbon's predefined sizes * /
} */

.modal-size .cds--modal-container {
  width: 380px; /* Fixed width for larger screens */
  max-width: 100%; /* Ensures that the modal is not wider than the screen on smaller devices */
  margin: auto; /* This will center the modal horizontally */
}

.active-response {
  background-color: #7495e9; /* Blue background for active state */
  color: white;
  // ... other styles for active button ...
}

/* Dogovor - Agreement (Green) */
.obisk-modal-button.dogovor { 
  background-color: #28a745; 
  color: #ffffff; 
}

/* Ne želi - Refusal (Deep Blue) */
.obisk-modal-button.nezeli { 
  background-color: #1f2e91; 
  color: #ffffff; 
}

/* Ni na voljo - Unavailable (Light Cyan) */
.obisk-modal-button.ninavoljo { 
  background-color: #00bcd4; 
  color: #000000; 
}

/* Že ima - Already Possessed (Medium Blue) */
.obisk-modal-button.zema { 
  background-color: #007bff; 
  color: #ffffff; 
}

/* Ni doma - Absent (Dark Blue/Gray) */
.obisk-modal-button.nidoma { 
  background-color: #384e64; 
  color: #ffffff; 
}

/* Prodano - Sold (Bright Green) */
.obisk-modal-button.prodano { 
  background-color: #3bcf56; 
  color: #ffffff; 
}
//.obisk-modal-button.preklici { background-color: #343a40; } /* Dark */


/* Adjust primary and secondary button colors */

//.modal-size .cds--btn--secondary { background-color: #f44336; } /* Red, for "Prekliči" */

/* Hide the primary button */
.hide-primary-button .cds--btn--primary {
  display: none;
}