// agentron-frontend/src/content/XLSUploadPage/XLSUploadPage.module.scss
.pageLayout {
    display: flex;
    flex-direction: row;
    padding-left: 10px; // Adjust as needed
    padding-right: 10px; // Adjust as needed
  }
  
  .console {
    flex: 1;
    background-color: #333;
    color: #fff;
    padding: 10px;
    height: 80vh; // Adjust based on your layout needs
    overflow-y: auto; // Enable scrolling for overflow
    max-height: 80vh; 
  }
  
  .content {
    flex: 1;
  }